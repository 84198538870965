<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ formTitle() }}</h4>
        </template>
        <b-container fluid>
          <b-row>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-overlay :show="loading">
                  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
                    <b-row>
                        <!-- -----------------Add More Start------------------- -->
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenBtriService.participant_id') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenService.tea_garden_name') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.name') }} </b-th>
                                <b-th class="text-center">{{ $t('globalTrans.contact_no') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.email') }}</b-th>
                                <b-th class="text-center">
                                  <b-form-checkbox
                                      id="checkbox-all"
                                      v-model="checkUncheckAll"
                                      name="checkbox-all"
                                  >
                                    {{ $t('globalTrans.checkAll') }}
                                  </b-form-checkbox>
                                </b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="formData.details && formData.details.length">
                                  <b-tr v-for="(item, index) in formData.details" :key="index">
                                    <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                    <b-td class="text-center">{{ item.participant_id }}</b-td>
                                    <b-td class="text-center">{{ getGardenName(item.garden_id) }}</b-td>
                                    <b-td class="text-center">{{ currentLocale === 'en' ? item.participate_name_en : item.participate_name_bn }}</b-td>
                                    <b-td class="text-center">{{item.contact_no | mobileNumber }}</b-td>
                                    <b-td class="text-center">{{item.email }}</b-td>
                                    <td class="text-center">
                                      <b-form-checkbox
                                          name="checked"
                                          v-model="item.is_participate"
                                          @change="checkUncheck"
                                          :value=1
                                          :unchecked-value=0
                                        >
                                        </b-form-checkbox>
                                    </td>
                                  </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                    </b-row>
                    <b-row class="text-right mb-3">
                        <b-col>
                          <b-row class="text-left mb-3">
                              <b-col>
                                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                              </b-col>
                          </b-row>
                        </b-col>
                        <b-col>
                          <b-button type="submit" @click="formData.status = 1" variant="primary" class="mr-2">{{$t('globalTrans.save')}}</b-button>
                          <router-link :to="{ path: '/tea-garden-service/btri/participate-list'}" class="btn btn-danger btn-sm">
                              {{ $t('globalTrans.cancel') }}
                          </router-link>
                        </b-col>
                    </b-row>
                  </b-form>
                </b-overlay>
              </ValidationObserver>
          </b-row>
        </b-container>
      </card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { participateStore, getNominationData } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
data () {
  return {
    plantingMaterialList: [],
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      formData: {
        garden_id: 0,
        schedule_main_id: 0,
        schedule_id: 0,
        fiscal_year_id: 0,
        event_type: 0,
        place: '',
        status: 0,
        remarks: '',
        details: []
      },
      details: {
        garden_id: 0,
        participate_name_en: '',
        participate_name_bn: '',
        designation_en: '',
        designation_bn: '',
        contact_no: '',
        email: ''
      },
      is_disable: false,
      isGardenAdmin: false,
      participate_name_en_error: '',
      participate_name_bn_error: '',
      designation_en_error: '',
      designation_bn_error: '',
      contact_no_error: '',
      email_error: '',
      editID: 0,
      selectAll: false,
      checkUncheckAll: false
  }
},
created () {
  this.user_id = this.$store.state.Auth.authUser.user_id

  if (this.$route.params.id) {
    const temp = this.getScheduleData()
    this.formData = temp
    this.formData.schedule_main_id = temp.id
  }
},
computed: {
  currentLocale () {
    return this.$i18n.locale
  },
  fiscalYearList () {
    return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
  },
  eventTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
      ]
      return list
    },
  serviceList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => [20, 21].includes(parseInt(item.value)))
  },
  valleyList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterValleyList
  },
  madeTeaGradeList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterMadeTeaGradeList
  },
  masterCloneNameList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterCloneNameList
  },
  masterTeaGardenGenInfoList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
  }
},
watch: {
  checkUncheckAll: function (newValue) {
    if (newValue) {
      this.formData.details.map(item => {
        item.is_participate = 1
      })
    } else {
      this.formData.details.map(item => {
        item.is_participate = 0
      })
    }
  }
},
methods: {
  back () {
    this.$router.go(-1)
  },
  getGardenName (id) {
    const data = this.masterTeaGardenGenInfoList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  },
  async getScheduleData () {
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.getData(teaGardenServiceBaseUrl, `${getNominationData}/${this.$route.params.id}`)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.formData.details = result.data
    } else {
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: result.message,
        color: '#D6E09B'
      })
      this.$router.push({ path: '/tea-garden-service/btri/participate-list' })
    }
    this.loading = false
  },
  async saveUpdate () {
    if (!this.formData.details.length) {
      return this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: 'Please add at least one participant!!!'
      })
    }
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.postData(teaGardenServiceBaseUrl, participateStore, this.formData)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
        color: '#D6E09B'
      })
      this.$router.push({ path: '/tea-garden-service/btri/participate-list' })
    } else {
      this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: result.message
      })
      this.$router.push({ path: '/tea-garden-service/btri/participate-list' })
    }
    this.loading = false
  },
  formTitle () {
      return (!this.$route.query.id) ? this.$t('teaGardenBtriService.Participants') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenBtriService.Participants') + ' ' + this.$t('globalTrans.modify')
  }
  }
}
</script>
